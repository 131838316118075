import React, { useEffect } from "react";
import pics1 from "./pdf/oasis1.jpg";
import { NavLink } from "react-router-dom";
import Gallery from './Gallery';

const Home = () => {
  return (
    <React.Fragment>
      <div className="mission-section">
        <h1
          style={{
            fontSize: "2.4rem",
            textTransform: "uppercase",
            textShadow: "0rem 0rem 0.4rem black",
          }}
        >
          Our Misssion
        </h1>
        <p className="style-para">
          To make a change in our society by reforming our students to be
          loyal,sincere and responsible indivisuals.
        </p>
      </div>
      <Gallery/>
      <div className="mission-content">
        <div className="mission-cont1">
          <img src={pics1} style={{ width: "100%" }} />
        </div>
        <div className="mission-cont2">
          
          <h1>Message from the desk of Principal Sir</h1>
         <hr></hr>
          <p style={{ fontSize: "1.4rem", textAlign: "justify" }}>
            Oasis International school Baheri has established itself as a institution in the field of education.
            I am pleased to inform you that ,Our priority is to provide high quality education to the students,their all-round
            development and to inculcate the feeling of nationalism in them and special facilities to the poor students for their studies.
            Hope from all the residents that Oasis International School Baheri will continue to move forward on the path of progress.
             </p>
             <br/>
             <h3>Best wishes<br/>
             
             principal Sir
             </h3>
          <button
            type="button"
            style={{
              padding: "0.5rem 3rem",
              margin: "2rem 0rem",
              backgroundColor: "#028f76",
              color: "white",
              outline: "none",
              border: "none",
            }}
          >
            <NavLink className="home-btn" to="/about">
              Know More
            </NavLink>
          </button>
        </div>
      </div>
      <div className="contact-set">
        <div className="inner-set">
          <h1>Oasis International school</h1>
          <hr></hr>
          <br/>
          <h3>
            Nainital Road Mandanpur
            <br />
            Baheri
            <br />
            District-Bareilly
            <br />
            Uttar Pradesh
            <br />
            India
          </h3>
          <br />
          <h3>Phone-9412437892, 8449364096, 7668968207</h3>
          <h3>oasisinternationalbaheri@gmail.com</h3>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Home;
