import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'; 
import Menubar from './Menubar';
import Menumobile from './Menumobile';
import logon from "./pdf/logon.jpg";

const Nav = () =>{
    const [menuShow,SetMenuShow] = useState(false);
    const handleMenuText = () =>{
       SetMenuShow(!menuShow);
    }
    return (
    <React.Fragment>
     <div className='main-desktop'>
        <div className='mobile-view'>
        {menuShow ? <Menubar data={handleMenuText} /> : <Menumobile data={handleMenuText}/>}
        </div>
        <div className='desktop-view'>
        <div className='logos-left'>
        <img src={logon} className='setLog' />
            <h1>Oasis International</h1>
                
        </div>
        <div className='menu-logos'>
            <ul>
            <li><NavLink activeClassName='active_class' to='/'>Home</NavLink></li>
                <li><NavLink activeClassName='active_class' to='/about'>About Us</NavLink></li>
                <li><NavLink activeClassName='active_class' to='/contact'>Contact</NavLink></li>
                <li><NavLink activeClassName='active_class' to='/mandatory'>Mandatory Public Disclosure</NavLink></li>
                <li><NavLink activeClassName='active_class' to='/admission'>Admissions</NavLink></li>

            </ul>
            </div>
        </div>
        </div>
</React.Fragment>
)

}
export default Nav;


