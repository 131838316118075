import React, { Component } from "react";
import Button from "./Button";
import { NavLink } from "react-router-dom";
import pics11 from "./pdf/pics11.jpg";
import pics12 from "./pdf/feesUps.jpg";

export default class Mandatory extends Component {
  constructor() {
    super();
    this.state = {
      fee: false,
    };
    this.state = {
      fees: false,
    };
  }
  feeHandler() {
    this.setState({ fee: !this.state.fee });
  }
  feesHandler() {
    this.setState({ fees: !this.state.fees });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.fee ? (
          <div className="fees-design">
            <img
              className="fees-view"
              onClick={() => this.feeHandler()}
              src={pics11}
            />
          </div>
        ) : null}
        {this.state.fees ? (
          <div className="fees-design">
            <img
              className="fees-view"
              onClick={() => this.feesHandler()}
              src={pics12}
            />
          </div>
        ) : null}
        <div className="center-main">
          <div className="center-left">
            <h1
              style={{
                fontSize: "2.4rem",
                textTransform: "uppercase",
                textShadow: "0rem 0rem 0.4rem black",
              }}
            >
              Mandatory disclosure
            </h1>
          </div>
          <div className="center-right">
            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              General Information
            </h1>
            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Information </th>
                <th>Details</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Name of the school</td>
                <td>Oasis International School</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Affiliation no.</td>
                <td>2132702</td>
              </tr>
              <tr>
                <td>3</td>
                <td>School Code</td>
                <td>61101</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Complete Addres with pin code</td>
                <td>
                  Nainital Road, Mandanpur Baheri 243201, Dist- Bareilly U.P
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Principal name and Qualification</td>
                <td>Mr Alok Pandey (M.A) B.Ed</td>
              </tr>
              <tr>
                <td>6</td>
                <td>School Email id</td>
                <td>oasisinternationalbaheri@gmail.com</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Contact Details</td>
                <td>9412437892,7500742277,7668968207</td>
              </tr>
            </table>
            {/* table two */}
            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              Documents and Information
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Documents/Information </th>
                <th>Upload Documents</th>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  Copies of Affiliation/Upgradation letter and recent extension
                  of Affiliation
                </td>
                <td className="text-alignment">
                  <NavLink to="/viewpdfs">
                    <Button />
                  </NavLink>
                </td>
              </tr>

              <tr>
                <td>2</td>
                <td>
                  Copies of Societies/Trust/Company Registration renewal
                  Certificate as Applicable
                </td>
                <td className="text-alignment">
                  <NavLink to="/society">
                    <Button />
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  Copy of No Objection Certificate(NOC) Issued,If Applicable by
                  the state govt/UT
                </td>
                <td className="text-alignment">
                  <NavLink to="/viewpdf">
                    <Button />
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  Copies of recognition Certificate Under RTE ACT 2009,And it's
                  renewal if Applicable
                </td>
                <td className="text-alignment">
                  <NavLink to="/viewpdf">
                    <Button />
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  Copy of Valid Building safety Certificate as per the national
                  building code
                </td>
                <td className="text-alignment">
                  <NavLink to="/building">
                  <Button />
                  </NavLink>
                                  </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  Copy of valid fire safety Certificate issued by the Competent
                  authority
                </td>
                <td className="text-alignment">
                <NavLink to="/fire">
                  <Button />
                  </NavLink>

                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  Copy of the DEO certificate submitted by the school for
                  affilaition/Upgradation/Extension of affiliation or self
                  certification by school
                </td>
                <td className="text-alignment">
                  <Button />
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  Copies of valid water health and sanitation certificates
                </td>
                <td className="text-alignment">
                  <Button />
                </td>
              </tr>
            </table>

            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              Results and Academics
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Documents/Information </th>
                <th>Upload Documents</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Fee Structure of the school</td>
                <td
                  className="text-alignment"
                  onClick={() => this.feesHandler()}
                >
                  <Button />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Annual Academic Calender</td>
                <td
                  className="text-alignment"
                  onClick={() => this.feeHandler()}
                >
                  <Button />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>List of school management committee(SMC)</td>
                <td className="text-alignment">
                <NavLink to="/committee">
                    <Button />
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>List of parents teachers association(PTA) members</td>
                <td className="text-alignment">
                <NavLink to="/association">
                    <Button />
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  Last three years result of the board examination as per
                  applicability
                </td>
                <td className="text-alignment">
                  <Button />
                </td>
              </tr>
            </table>

            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              Result Class: X
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Year </th>
                <th>No.of Registered students</th>
                <th>No.of Passed students</th>
                <th>Pass Percentage</th>
                <th>Remarks</th>
              </tr>
              <tr>
                <td>1</td>
                <td>2020</td>
                <td>70</td>
                <td>52</td>
                <td>75%</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>2021</td>
                <td>168</td>
                <td>168</td>
                <td>100%</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>2022</td>
                <td>124</td>
                <td>110</td>
                <td>90%</td>
                <td></td>
              </tr>
            </table>

            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              Result Class: XII
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Year </th>
                <th>No.of Registered students</th>
                <th>No.of Passed students</th>
                <th>Pass Percentage</th>
                <th>Remarks</th>
              </tr>
              <tr>
                <td>1</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>2</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </table>

            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              Staff (Teaching)
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Information </th>
                <th>Details</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Principal</td>
                <td>1</td>
              </tr>
              <tr>
                <td rowspan="4">2</td>
                <td>Total no. of teachers</td>
                <td>22</td>
              </tr>
              <tr>
                <td>PGT</td>
                <td>6</td>
              </tr>
              <tr>
                <td>TGT</td>
                <td>7</td>
              </tr>
              <tr>
                <td>PRT</td>
                <td>9</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Teachers section ratio</td>
                <td>1.5</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Details of special educator</td>
                <td>-</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Details of counsellor and welness teacher</td>
                <td>1</td>
              </tr>
            </table>
            <h1 style={{ fontSize: "1.6rem", textTransform: "uppercase" }}>
              School Infrastructure
            </h1>

            <table style={{ margin: "2rem 0rem" }}>
              <tr>
                <th>SL NO.</th>
                <th>Information </th>
                <th>Details</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Total campus area of the school (IN SQUARE METER)</td>
                <td>7058.24 Sq m.</td>
              </tr>
              <tr>
                <td>2</td>
                <td>No. and size of the class rooms(IN SQUARE FTMTR)</td>
                <td>
                  17 = 500 F/m<sup>2</sup>{" "}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  No. and size of the laboraties incuding computer labs(IN SQ
                  Ft.)
                </td>
                <td>
                5 = 600 sq ft.
                </td>
              </tr>
              <tr> 
                <td>4</td>
                <td>Internet Facility Y/N</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>5</td>
                <td>No.of girls toilets</td>
                <td>5</td>
              </tr>
              <tr>
                <td>6</td>
                <td>No.of boys toilets</td>
                <td>6</td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  Link of youtube video of the inspection of school covering the
                  infrastructure of the school
                </td>
                <td>N.A</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="contact-set">
          <div className="inner-set">
            <h1>Oasis International school</h1>
            <h3>
              Nainital Road Mandanpur
              <br />
              Baheri
              <br />
              District-Bareilly
              <br />
              Uttar Pradesh
              <br />
              India
            </h3>
            <br />
            <h3>Phone- 8449364096, 7668968207</h3>
            <h3>oasisinternationalbaheri@gmail.com</h3>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
