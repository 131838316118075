import React,{ useState }  from 'react';
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import pd from './pdf/affiliationup.pdf';

const Viewpdf = () =>{
    const [numPages,setNumPages] = useState(null);
    const [pageNumber,setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({numPages}){
      setNumPages(numPages);
      setPageNumber(1);
    }
    function changePage(offSet){
      setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }
    function changePageBack(){
      changePage(-1)
    }
    function changePageNext(){
      changePage(+1)
    }
return(
 <React.Fragment>
  <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'2rem 0rem'}}>
    <div className='inner'>
 <Document file={pd}  onLoadSuccess={onDocumentLoadSuccess}>
    <Page pageNumber={pageNumber}/>
  </Document>
  <br />
  <h3>{pageNumber > 1 && <button className='pdf-button' onClick={changePageBack}>Prev Page</button>} Page  {pageNumber} and {numPages}  { pageNumber < numPages &&  <button className='pdf-button' onClick={changePageNext}>Next Page</button>}
 </h3>
 </div>
   </div>
   <div className='contact-set'>
        <div className='inner-set'>
      <h1>Oasis International school</h1>
      <h3>Nainital Road Mandanpur<br />
      Baheri<br />
      District-Bareilly<br/>
      Uttar Pradesh<br />
      India
      </h3>
      <br />
      <h3>Phone- 8449364096, 7668968207</h3>
      <h3>oasisinternationalbaheri@gmail.com</h3>
      </div>
      </div>
  </React.Fragment>
)

}
export default Viewpdf;