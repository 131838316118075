import React from "react";

const About = () => {
  return (
    <React.Fragment>
      <div className="center-main">
        <div className="center-left">
          <h1
            style={{
              fontSize: "2.4rem",
              textTransform: "uppercase",
              textShadow: "0rem 0rem 0.4rem black",
            }}
          >
            About Oasis International
          </h1>
        </div>
        <div className="center-rightsz">
          <p style={{ fontSize: "1.6rem", textTransform: "capitalize" }}>
            Oasis is a school governed and supported by GILL WELFARE EDUCATIONAL
            SOCIETY, Successfully functioning since 2011.It is a day school from
            class I to X,Providing an overall developement to its students with
            academic,sports and co-curricular activities.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            margin: "2rem 0rem",
          }}
        >
          <div
            className="set1"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#fff100",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Cooking Classes</h1>
          </div>
          <div
            className="set2"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#c56cf0",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Dancing Classes</h1>
          </div>
          <div
            className="set3"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#32ff7e",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Art and Craft</h1>
          </div>
          <div
            className="set4"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#218c74",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Cricket</h1>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            margin: "2rem 0rem",
          }}
        >
          <div
            className="set1"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#0fbcf9",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>BasketBall</h1>
          </div>
          <div
            className="set2"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#05c46b",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Football</h1>
          </div>
          <div
            className="set3"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#f53b57",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Badminton</h1>
          </div>
          <div
            className="set4"
            style={{
              flex: "1",
              textAlign: "center",
              flexWrap: "wrap",
              backgroundColor: "#3c40c6",
              boxShadow: "0rem 0rem 0.4rem black",
              margin: "0rem 1rem",
              padding: "2rem 1rem",
            }}
          >
            <h1>Volley Ball</h1>
          </div>
        </div>
      </div>
      <div className="contact-set">
        <div className="inner-set">
          <h1>Oasis International school</h1>
          <hr></hr>
          <br/>
          <h3>
            Nainital Road Mandanpur
            <br />
            Baheri
            <br />
            District-Bareilly
            <br />
            Uttar Pradesh
            <br />
            Indian
          </h3>
          <br />
          <h3>Phone-9412437892, 8449364096, 7668968207</h3>
          <h3>oasisinternationalbaheri@gmail.com</h3>
        </div>
      </div>
    </React.Fragment>
  );
};
export default About;
