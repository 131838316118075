import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer>
          <p>Copyright @ Oasis International</p>
        </footer>
      </React.Fragment>
    );
  }
}
