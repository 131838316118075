import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import logon from "./pdf/logon.jpg";


const Menumobile = (props) => {
  return (
    <React.Fragment>
      <div className="mains-hamburger">
        <div className="hamburger-left">
          <h1>Oasis International</h1>
          <img src={logon} className='setLog' />
        </div>
        <div className="hamburger-right">
          <GiHamburgerMenu
            onClick={() => props.data()}
            style={{ fontSize: "2.4rem" }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Menumobile;
