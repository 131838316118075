import React,{useState} from "react";
import pics1 from './pdf/pics1.jpg'; 
import playing from './pdf/playing.jpg'; 
import player1 from './pdf/player1.jpg'; 
import player2 from './pdf/player2.jpg'; 
import rangoli from './pdf/RANGOLI.jpg'; 
import girls from './pdf/girls.jpg'; 


const Gallery = () => {
 

  return (
    <React.Fragment>
      <div className="center-main">
        <div className="center-left">
          <h1
            style={{
              fontSize: "2.4rem",
              textTransform: "uppercase",
              textShadow: "0rem 0rem 0.4rem black",
            }}
          >
            Gallery
          </h1>
        </div>
         
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            margin: "2rem 0rem",
          }}
        >
       <div className="centralize">
       <div className="one1">
         <img src={pics1} className='size1' />
         <h1>Computer Lab</h1>
       </div>
       <div className="one1">
       <img src={player1} className='size1'/>
       <h1>Race</h1>
        </div>
        <div className="one1">
        <img src={playing} className='size1' />
        <h1>School Playground</h1>
        </div>
       </div>
       <div className="centralize">
       <div className="one1">
         <img src={player2} className='size1' />
         <h1>NTA Exam</h1>
       </div>
       <div className="one1">
       <img src={rangoli} className='size1'/>
       <h1>Rangoli Competition</h1>
        </div>
        <div className="one1">
        <img src={girls} className='size1' />
        <h1>Dance performance</h1>
        </div>
       </div>
        </div>
        
      </div>
      
    </React.Fragment>
  );
};
export default Gallery;
