import React,{Component} from 'react';
import vid from './production ID_5198159.mp4';
import Home from './Home';

export default class Slide extends Component{
    render(){
        return (<React.Fragment>
            <div className='centered'>
            <h1 className='positionSet'>Oasis International</h1>
         <video muted autoPlay>
            <source src={vid} type="video/mp4" />
           
         </video>
         </div>
         <Home />
        </React.Fragment>)
    }
}