import React, { Component } from "react";
import Nav from "./Nav";
import { Route, Switch } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Slide from "./Slide";
import Mandatory from "./Mandatory";
import Footer from "./Footer";
import Viewpdf from "./Viewpdf";
import Viewpdfs from "./Viewpdfs";
import Fire from "./Fire";
import Building from "./Building";
import Association from "./Association";
import Committee from "./Committee";
import Admission from "./Admission";
import Society from "./Society";
import poster from "./pdf/oasisposter.jpg";

export default class App extends Component {
  state = {
    changeState: false,
  };
  componentDidMount() {
    this.setState({ changeState: true });
  }
  render() {
    const handlerClick = () => {
      this.setState({ changeState: false });
    };
    return (
      <div>
        <Nav />
        {this.state.changeState ? (
          <div className="modal-design" onClick={handlerClick}>
            <img src={poster} />
          </div>
        ) : null}
        <Switch>
          <Route exact path="/" component={Slide} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/mandatory" component={Mandatory} />
          <Route exact path="/viewpdf" component={Viewpdf} />
          <Route exact path="/viewpdfs" component={Viewpdfs} />
          <Route exact path="/society" component={Society} />
          <Route exact path="/fire" component={Fire} />
          <Route exact path="/building" component={Building} />
          <Route exact path="/association" component={Association} />
          <Route exact path="/committee" component={Committee} />
          <Route exact path="/admission" component={Admission} />
        </Switch>
        <Footer />
      </div>
    );
  }
}
