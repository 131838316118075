import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import admission from "./pdf/admission.pdf";

const Admission = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }
  function changePageBack() {
    changePage(-1);
  }
  function changePageNext() {
    changePage(+1);
  }
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "2rem 0rem",
        }}
      >
        <Document file={admission} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <br />
        <h3>
          {pageNumber > 1 && (
            <button className="pdf-button" onClick={changePageBack}>
              Prev Page
            </button>
          )}{" "}
          Page {pageNumber} and {numPages}{" "}
          {pageNumber < numPages && (
            <button className="pdf-button" onClick={changePageNext}>
              Next Page
            </button>
          )}
        </h3>
      </div>
    </React.Fragment>
  );
};
export default Admission;
