import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  return (
    <React.Fragment>
      <div className="mains-hamburger">
        <div className="hamburger-left">
       
          <h1>Oasis International</h1>
        </div>
        <div className="hamburger-right">
          <GiHamburgerMenu
            onClick={() => props.data()}
            style={{ fontSize: "2.4rem" }}
          />
        </div>
      </div>

      <div className="menu-collapse">
        <ul>
          <li>
            <NavLink
              activeClassName="active_class"
              to="/"
              onClick={() => props.data()}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active_class"
              to="/about"
              onClick={() => props.data()}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active_class"
              to="/contact"
              onClick={() => props.data()}
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active_class"
              to="/mandatory"
              onClick={() => props.data()}
            >
             Mandatory Public Disclosure
            </NavLink>
      </li>
      <li>
            <NavLink
              activeClassName="active_class"
              to="/admission"
              onClick={() => props.data()}
            >
             Admissions
            </NavLink>
            
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
export default Navbar;
