import React, { useState } from "react";
const Button = () => {
  const [buttons,setButtons] = useState(false);
   const handleClicked = () => {
    setButtons(!buttons);
  };
 return (
      <React.Fragment>
        <button
          className={buttons ? "button-updated" : "button-design"}
          onClick={handleClicked}
        >
          View Document
        </button>
      </React.Fragment>
    );
  }
export default Button;
