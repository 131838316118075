import React from "react";
import Maps from "./Maps";

const Contact = () => {
  return (
    <React.Fragment>
      <div className="center-main">
        <div className="center-left">
          <h1
            style={{
              fontSize: "2.4rem",
              textTransform: "uppercase",
              textShadow: "0rem 0rem 0.4rem black",
            }}
          >
            Contact us
          </h1>
        </div>
        <div className="center-rights">
          <Maps />
        </div>
      </div>
      <div className="contact-set">
        <div className="inner-set">
          <h1>Oasis International school</h1>
          <hr></hr>
          <br/>
          <h3>
            Nainital Road Mandanpur
            <br />
            Baheri
            <br />
            District-Bareilly
            <br />
            Uttar Pradesh
            <br />
            India
          </h3>
          <br />
          <h3>Phone- 9412437892, 8449364096, 7668968207</h3>
          <h3>oasisinternationalbaheri@gmail.com</h3>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Contact;
